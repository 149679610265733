/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bar-container {
  display: flex;
  float: right;
  margin-left: 10px;
  justify-content: space-between;
  width: 30px;
  height: 80px;
}

.bar {
  width: 20%;
  height: 0;
  background-color: #759DEC;
  border-radius: 5px;
}

.middle {
  height: 0;
}

.hidden {
  visibility: hidden;
}

.shadow-box {
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.7), 10px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4vh;
}

.shadow-back-button {
  box-shadow: inset 4px 4px 8px 1px rgba(0,0,0,0.2), inset -4px -4px 8px 1px rgba(255,255,255,0.7);
  width: 6vh;
  height: 6vh;
  display: flex;
  align-items: 'center';
  border-radius: 50%;
}

.shadow-update-button {
    box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.7), 10px 10px 20px rgba(0, 0, 0, 0.15) !important;
    border-radius: 3vh !important;
    font-family: "Google Sans", sans-serif !important;
    font-size: 2vh !important;
    text-transform: none !important;
    width: 17vh;
    height: 5.5vh;
    background-color: #FFFFFF !important;
}

.tool-bar {
  justifyContent: 'space-between';
  padding: 8vh 0 2vh 0 !important;
  width: 100%;
}